function getServerType() {
  const { hostname } = window.location
  switch (hostname) {
    case "test.workbench.cloudhub360.com":
      return "test"
    case "demo.workbench.cloudhub360.com":
      return "demo"
    default:
      return "test"
  }
}

function getRoutes() {
  return {
    ROUTE: {
      COLLECTIONS: 'collections',
      DETAILS: 'details'
    }
  }
}

function getUrl() {
  // const BASE = 'http://ec2-35-176-120-96.eu-west-2.compute.amazonaws.com:5000';
  try {
    let BASE_APPLICATION = '';
    let BASE_URL_IMPORT_EXPORT = '';
    let AUTH0_BASE_URL = "";
    let CONTRACT_SENTINEL_URL = '';

    /* BASE URL SEGREGATION BASED ON ENVIRONMENT STARTS HERE */
    const server = getServerType()

    // Test Server 
    if (server === 'test') {
      BASE_APPLICATION = 'https://test.server.cloudhub360.com';                            // For Workbench Backened                
      BASE_URL_IMPORT_EXPORT = "https://test.io.cloudhub360.com";
      AUTH0_BASE_URL = "https://test.um.cloudhub360.com";
      CONTRACT_SENTINEL_URL = "https://test.server.contractsentinel.com";
    }
    // Demo Server 
    else if (server === 'demo') {
      BASE_APPLICATION = 'https://demo.server.cloudhub360.com';                            // For Workbench Backened
      BASE_URL_IMPORT_EXPORT = "https://demo.io.cloudhub360.com";
      AUTH0_BASE_URL = "https://demo.um.cloudhub360.com";
      CONTRACT_SENTINEL_URL = "https://app.server.contractsentinel.com";
    }

    return {
      URL: {
        COLLECTIONS: {
          GET: BASE_APPLICATION + '/api/collections',
          DELETE: BASE_APPLICATION + '/api/collections/$cid',
          KEY: 'collections'
        },
        FILTERS: {
          GET: BASE_APPLICATION + '/api/collections/$id/filters',
          KEY: 'filters'
        },
        DOCUMENT: {
          GET: BASE_APPLICATION + '/api/collections/$cid/documents/$did',
          POST: BASE_APPLICATION + '/api/collections/$cid/documents',
          DELETE: BASE_APPLICATION + '/api/collections/$cid/documents/$did',
          DOWNLOAD: BASE_APPLICATION + '/api/static/collections/$cid/documents/$did',
          KEY: 'document',
          SIMILAR_DOCUMENTS: BASE_APPLICATION + '/api/collections/$cid/documents/$did/similar-documents',
          STRUCTURE: BASE_APPLICATION + '/api/collections/$cid/documents/$did/structure-text',
          SIMILAR_CLAUSES: BASE_APPLICATION + '/api/collections/$cid/documents/$did/clauses/$index/similar-clauses',
        },
        DOCUMENTS: {
          GET: BASE_APPLICATION + '/api/collections/$id/documents',
          KEY: 'documents'
        },
        ENTITIES: {
          GET: BASE_APPLICATION + '/api/collections/$cid/documents/$did/entities',
          PUT: BASE_APPLICATION + '/api/collections/$cid/documents/$did/entities',
          KEY: 'entities',
        },
        ENTITIES_TOKEN: {
          GET: BASE_APPLICATION + '/api/collections/$cid/documents/$did/values',
          KEY: 'entities_token',
        },
        BOUNDING_BOX: {
          GET: BASE_APPLICATION + '/api/collections/$cid/documents/$did/images/$image/bboxes?width=$width&height=$height',
          KEY: 'bounding_box',
        },
        BOUNDING_BOX_CREATE_DATA: {
          GET: BASE_APPLICATION + '/api/collections/$cid/documents/$did/images/$image/bboxes/text?width=$width&height=$height&x-min=$x1&y-min=$y1&x-max=$x2&y-max=$y2',
          KEY: 'bounding_box_create',
        },
        MAPPINGS: {
          GET: BASE_APPLICATION + '/api/collections/$cid/documents/$did/mappings',
          POST: BASE_APPLICATION + '/api/collections/$cid/documents/$did/mappings',
          KEY: 'mappings'
        },
        GROUPS: {
          GET: BASE_APPLICATION + '/api/groups',
          KEY: 'groups'
        },
        RESOURCE_LOCK: {
          POST: BASE_APPLICATION + '/api/documents/$did/lock',
          GET: BASE_APPLICATION + '/api/documents/$did/lock',
          DELETE: BASE_APPLICATION + '/api/documents/$did/lock',
          KEY: 'lock'
        },
        LOGIN: {
          POST: AUTH0_BASE_URL + '/api/user/login',
          KEY: 'login',
          CLOUDHUB_LOGIN: AUTH0_BASE_URL + '/api/user/login',
          CONTRACT_SENTINEL_LOGIN: CONTRACT_SENTINEL_URL + '/api/login',
        },
        IMPORTEXPORT: {
          POST: BASE_URL_IMPORT_EXPORT + '/api/connector/export/file',
          KEY: 'importexport'
        },
        ORGANISATION: {
          GET: AUTH0_BASE_URL + '/api/customer',
          KEY: 'customerOrg',
          CLOUDHUB_ORG: AUTH0_BASE_URL + '/api/customer',
          CONTRACT_SENTINEL_ORG: CONTRACT_SENTINEL_URL + '/api/organisation'
        },
        AUTH0: {
          BASE_URL: AUTH0_BASE_URL,
          key: 'auth0',
          CLOUDHUB_AUTH: AUTH0_BASE_URL,
          CONTRACT_SENTINEL_AUTH: CONTRACT_SENTINEL_URL
        }
      }
    }
  } catch (e) {
    console.warn(e);
  }
}

export {
  getRoutes,
  getUrl
}
