import React, { useEffect, useState } from "react"
import { Navbar, NavItem } from "reactstrap"
import { NavLink } from "react-router-dom"
import classnames from "classnames"

import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"
// import credential from "./../../../configs/credential"
// import { history } from "../../../history"
import { handleUserDetails } from '../../../utility/Utility'

import defaultAvatar from "../../../assets/img/portrait/default-avatar.png"
import cloudhubLogo from "../../../assets/icons/cloudhub-logo.png"
import contractSentinelLogo from "../../../assets/icons/contract-sentinel-logo.png"
import {
  // setEncryptedItem,
  getDecryptedItem
} from "../../../utility/encryptedStorage"

const defaultImg = 'https://icons-for-free.com/iconfiles/png/512/business+costume+male+man+office+user+icon-1320196264882354682.png';

const ThemeNavbar = ({
  // toggleSidebarMenu,
  // sidebarState,
  sidebarVisibility,
  // currentLang,
  changeCurrentLang,
  handleAppOverlay,
  // appOverlayState,
  navbarColor,
  navbarType,
  horizontal,
  scrolling,
  origin
}) => {
  const [data, setData] = useState({});
  // const [loggedUserData, setLoggedUserData] = useState({});
  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"]
  const navbarTypes = ["floating", "static", "sticky", "hidden"]
  // var userId = '';

  // useEffect(()=>{
  //   getName();
  //   setUserData();
  // },[getDecryptedItem('data')]);

  // const getName = () => {
  //   // let email = getDecryptedItem("email");
  //   // let name = email?email.split('@')[0]:'';
  //   var username;
  //   var loginData = getDecryptedItem('data')
  //   setLoggedUserData(loginData);
  //   if (loginData !== undefined && loginData !== null && typeof loginData.userAuthData !== undefined) {
  //     if (loginData.userAuthData.roleName[0] === "CH360SuperAdmin") {
  //       username = 'Super Admin'
  //     }
  //     else if (loginData.userAuthData.roleName[0] === "PartnerAdmin") {
  //       username = loginData.name;
  //     }
  //     else if (loginData.userAuthData.roleName[0] === "CustomerAdminSetup") {
  //       username = loginData.name;
  //     } else if (loginData.userAuthData.roleName[0] === "CustomerAdmin" || loginData.userAuthData.roleName[0] === "CustomerDesigner" || loginData.userAuthData.roleName[0] === "User") {
  //       username = loginData.userName;
  //     }

  //   }
  //   return username;
  // }

  // const getRoleName = () => {
  //   let loginData = getDecryptedItem('data')
  //   if (loginData !== undefined && loginData !== null && typeof loginData.userAuthData !== undefined) {
  //     var roleNameList = loginData.userAuthData.roleName;
  //     if (roleNameList[0] === "CustomerAdminSetup") {
  //       roleNameList = ["CustomerAdmin"]
  //     } else {
  //       roleNameList = roleNameList;
  //     }
  //   }
  //   return roleNameList;
  // }

  // const setUserData = () => {
  //   const data = credential;
  //   let urlMail, email = getDecryptedItem("email") || '';
  //   if (!email) {
  //     urlMail = window.location.href.split("=")?.[1];
  //     if (urlMail) {
  //       setEncryptedItem("loggedIn", true);
  //       setEncryptedItem("email", urlMail);
  //       setEncryptedItem("company", credential[0].company);
  //       email = urlMail;

  //     } else {
  //       history.push("/login");
  //       return;
  //     }
  //   }
  //   let res = data.filter((el) => {
  //     if (el.email == email)
  //       return true;
  //   });

  //   console.log(res);
  //   if (res.length > 0) {
  //     setData(res[0]);
  //   } else {
  //     setData({
  //       name: getName() || "CH360USER",
  //       email: getDecryptedItem("email") || "",
  //       // status:credential[0].status,
  //       status: getRoleName() || "ROLE",
  //       company: credential[0].company || "",
  //       image: 'https://icons-for-free.com/iconfiles/png/512/business+costume+male+man+office+user+icon-1320196264882354682.png'
  //     });
  //   }
  //   if (urlMail)
  //     history.push("/");
  // }

  // const setUserNavData = () => {
  //   //check if the user is already logged in
  //   if (getDecryptedItem("loggedIn") === "true") {
  //     //check if the data is already set

  //     return;
  //   }
  //   //check the url
  //   const mailArr = window.location.search.split("$");
  //   if (mailArr.length > 1) {
  //     const userId = mailArr[1];
  //     //call the api and set the data
  //     console.log(userId);

  //   }

  // }

  const sessionStorageUserData = getDecryptedItem("user-data")

  useEffect(() => {
    handleUserDetails().then(async (response) => {
      if (response) {
        const { roles, userIconPath, name: userName, username, customerName } = response;
        const userRoles = roles || []

        var profileImg = defaultImg;
        let name = 'USER'
        if (userRoles) {
          profileImg = userIconPath;
          name = userName;
          if (userRoles.includes("SuperAdmin")) {
            profileImg = defaultImg;
            name = 'Super Admin';
          } else if (userRoles.includes("Partner")) {
            profileImg = defaultImg;
          } else if (userRoles.includes("Customer Admin") || userRoles.includes("Customer Designer") || userRoles.includes("Customer End User")) {
            if (!profileImg) {
              profileImg = defaultImg;
            }
          }
        }

        setData({
          name: name,// response.name || "NA",
          email: username,// getDecryptedItem("email") || "",
          // status:credential[0].status,
          status: userRoles.filter(role => role !== "EndUser").join(", "), // getRoleName() || "ROLE",
          company: customerName, // credential[0].company || "",
          image: profileImg
        });
      }
    })
  }, [sessionStorageUserData]);

  return (
    <React.Fragment>
      <div className="content-overlay" />
      {/* <div className="header-navbar-shadow" /> */}
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light": navbarColor === "default" || !colorsArr.includes(navbarColor),
            "navbar-dark": colorsArr.includes(navbarColor),
            "bg-primary":
              navbarColor === "primary" && navbarType !== "static",
            "bg-danger":
              navbarColor === "danger" && navbarType !== "static",
            "bg-success":
              navbarColor === "success" && navbarType !== "static",
            "bg-info":
              navbarColor === "info" && navbarType !== "static",
            "bg-warning":
              navbarColor === "warning" && navbarType !== "static",
            "bg-dark":
              navbarColor === "dark" && navbarType !== "static",
            "d-none": navbarType === "hidden" && !horizontal,
            "floating-nav":
              (navbarType === "floating" && !horizontal) || (!navbarTypes.includes(navbarType) && !horizontal),
            "navbar-static-top":
              navbarType === "static" && !horizontal,
            "fixed-top": navbarType === "sticky" || horizontal,
            "scrolling": horizontal && scrolling,
            "navbar-cs": origin === 'contract-sentinel'
          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper position-relative">
                {
                  origin === 'contract-sentinel'
                    ?
                    <ul className="nav navbar-nav flex-row">
                      <NavItem>
                        <NavLink to="/" className="navbar-brand">
                          <img
                            src={origin === 'contract-sentinel' ? contractSentinelLogo : cloudhubLogo}
                            className="menu-icon main-logo main-logo-cs"
                            alt='brand'
                          />
                          <h1 className="brand-text text-primary ml-1 sub-title sub-title-cs">Contract Sentinel</h1>
                          <h1 className="sub-title sub-title-workbench">Workbench</h1>
                        </NavLink>
                      </NavItem>
                    </ul>
                    : <NavbarBookmarks
                      sidebarVisibility={sidebarVisibility}
                      handleAppOverlay={handleAppOverlay}
                    />
                }
              </div>

              {horizontal ? (
                <div className="logo d-flex align-items-center">
                  {/* <div className="brand-logo mr-50"></div> */}
                  <h2 className="text-primary brand-text mb-0">Dexninja</h2>
                </div>
              ) : null}
              <NavbarUser
                handleAppOverlay={handleAppOverlay}
                changeCurrentLang={changeCurrentLang}
                userName={`${data.name || ""}${data.status ? ` (${data.status})` : ""}`}
                userImg={data.image || defaultAvatar}
                company={data.company}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  )
}


export default ThemeNavbar
