import Axios from 'axios';
import { getUrl } from '../Config'
import { settingFromUrl, updateContractSentinal } from "../../configs/settingsFromUrl";
// import { updateContractSentinal } from "./Config/settingFromUrl";

import { store } from "../../redux/storeConfig/store"
import { getDecryptedItem, setEncryptedItem } from '../encryptedStorage';

const CONFIG = getUrl();
const CORS_WORKAROUND = '';//'https://cors-anywhere.herokuapp.com/';

const CLOUDHUB_BASE_URL = CONFIG.URL.AUTH0.CLOUDHUB_AUTH
const CONTRACT_SENTINEL_BASE_URL = CONFIG.URL.AUTH0.CONTRACT_SENTINEL_AUTH

/**
 * Header data object formation based on Loggedin user
 */
// var header_data = {
//   headers: {
//     user_email: '',
//     user_org: '',
//     username: ''
//   }
// };

const getHeaderData = () => {
  const userData = JSON.parse(getDecryptedItem("user-data"));

  const headers = {}

  if (userData) {
    const { username, customerId, name } = userData
    if (username) {
      headers.user_email = username
    }
    if (customerId) {
      headers.user_org = customerId
      headers.organisationId = customerId
    }
    if (name) {
      headers.username = name
    }
  }

  const token = getDecryptedItem("token");
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  return { headers }
}

///////////////////////////////////
function get(url, headerParam = null) {
  let head_data = getHeaderData();
  if (headerParam !== null) {
    head_data = {
      ...head_data,
      headers: {
        ...head_data.headers,
        ...headerParam
      }
    }
  }
  if (!url) {
    console.error("URL is a mandatory field to make an API call");
    return;
  }

  return new Promise((resolve, reject) => {
    Axios.get(url, head_data).then((data) => {
      resolve(data);
      // showLoader(false);
    }, (e) => {
      reject(e);
    });
  });
}

function post(url, param, headerParam = null, configParams) {
  let head_data = getHeaderData();
  if (headerParam !== null) {
    head_data = {
      ...head_data,
      headers: {
        ...head_data.headers,
        ...headerParam
      },
      ...configParams
    }
  }
  if (!url) {
    console.error("URL is a mandatory field to make an API call");
    return;
  }

  return new Promise((resolve, reject) => {
    Axios.post(url, param, head_data).then((resp) => {
      resolve(resp);
    }, (e) => {
      reject(e);
    });
  });

}

function put(url, param) {
  const head_data = getHeaderData();
  if (!url) {
    console.error("URL is a mandatory field to make an API call");
    return;
  }

  return new Promise((resolve, reject) => {
    //console.log(url, param, header_data);
    Axios.put(url, param, head_data).then((resp) => {
      resolve(resp);
    }, (e) => {
      console.error(e);
      reject(e);
    });
  });
}

function deleteApi(url, param) {
  const head_data = getHeaderData();
  if (!url) {
    console.error("URL is a mandatory field to make an API call");
    return;
  }

  return new Promise((resolve, reject) => {
    Axios.delete(url, head_data).then(resp => {
      resolve(resp);
    }, e => {
      console.error(e);
      reject(e);
    });
  });
}
///////////////////////////////////

/**
 * earlier header data object
 */
// const header_data = {
//     headers: {
//         user_email: getDecryptedItem("email"),
//         user_org: getDecryptedItem("company")
//     }
// }

/**
 * responsible for resource lock implementation
 */

function getLockStatus(data) {
  //console.log(`header data`,header_data);
  let did = data;
  let url = CORS_WORKAROUND + CONFIG.URL.RESOURCE_LOCK.GET;
  url = url.replace('$did', did);
  //console.log(`Url for getLockStatus`,url)
  // var userData = JSON.parse(getDecryptedItem("user-data"));
  // let header_data_lock={
  //     headers: {
  //         username: userData.username,
  //     }
  // };
  //console.log(`header header_data_lock`,header_data_lock);
  return new Promise((res, rej) => {
    get(url).then((resp) => {
      let data = resp.data;
      //console.log(`Response`,resp)
      res(data);

    }, (e) => {
      rej(e);
    });
  });
  // return new Promise((resolve, reject) => {
  //         get(url, header_data_lock).then((response) => {
  //             resolve(response.data);
  //             // showLoader(false);
  //         }, (e) => {
  //             reject(e);
  //         });
  // });
}

function acquireLock(data, param) {
  let did = data;
  let url = CORS_WORKAROUND + CONFIG.URL.RESOURCE_LOCK.POST;
  url = url.replace('$did', did);
  //console.log(`Url for acquireLock`,url)
  // var userData = JSON.parse(getDecryptedItem("user-data"));
  // let header_data_lock={
  //     headers: {
  //         username: userData.username,
  //     }
  // };

  return new Promise((res, rej) => {
    post(url, param).then((resp) => {
      res(resp.data);
    }, (e) => {
      rej(e);
    });
  });
}

function releaseLock(data) {
  return new Promise((res, rej) => {
    if (!data)
      rej();
    let did = data;
    let url = CORS_WORKAROUND + CONFIG.URL.RESOURCE_LOCK.DELETE;
    url = url.replace('$did', did);
    //console.log(`Url for releaseLock`,url)
    var userData = JSON.parse(getDecryptedItem("user-data"));
    if (!userData)
      rej();

    deleteApi(url).then((resp) => {
      let data = resp.data;
      res(data);

    }, (e) => {
      rej(e);
    });
  });
}

/**
 * ***************************************************
 */

/**
* APIs related to the user Login & User Details goes here
*/

function userLoginCloudhub(data) {
  let url = CORS_WORKAROUND + CONFIG.URL.LOGIN.CLOUDHUB_LOGIN;
  return new Promise((res, rej) => {
    post(url, data).then((resp) => {
      res(resp.data);
    }, (e) => {
      rej(e);
    });
  });
}

function userLoginContractSentinel(data) {
  let url = CORS_WORKAROUND + CONFIG.URL.LOGIN.CONTRACT_SENTINEL_LOGIN;
  return new Promise((res, rej) => {
    post(url, data).then((resp) => {
      res(resp.data);
    }, (e) => {
      rej(e);
    });
  });
}

/**
 * looking for the id of contract management id
 */
function getContractManagement() {
  return new Promise((resolve, reject) => {
    getCollection().then((resp) => {
      console.log(resp);
      resolve(resp);
    })
  })
}

function getCustomCall(url) {
  return new Promise((res, rej) => {
    get(url).then((resp) => {
      let data = resp.data;
      console.log(data);
      res(data);
    }, (e) => {
      rej(e);
    });
  });
}


function getCollections() {
  let url = CORS_WORKAROUND + CONFIG.URL.COLLECTIONS.GET;
  return new Promise((res, rej) => {
    get(url).then((resp) => {
      let data = resp.data;
      if (settingFromUrl('contract_sentinal'))
        data = updateContractSentinal(data);
      res(data);

    }, (e) => {
      rej(e);
    });
  });
}

/**
 * responsible for getting a single collection
 */
function getCollection(collectionId) {
  let url = CORS_WORKAROUND + CONFIG.URL.COLLECTIONS.GET + '/' + collectionId;
  return new Promise((res, rej) => {
    get(url).then((resp) => {
      let data = resp.data;
      res(data);

    }, (e) => {
      rej(e);
    });
  });
}

function getFilters(id) {
  let url = CORS_WORKAROUND + CONFIG.URL.FILTERS.GET;
  url = url.replace('$id', id);
  return new Promise((res, rej) => {
    get(url).then((resp) => {
      let data = resp.data;
      res(data);

    }, (e) => {
      rej(e);
    });
  });

}

function getDocuments(id, param, page = 0, size = 20) {
  if (settingFromUrl('contract_sentinal')) {
    //removing 'contract_sentinal' from id
    id = id.replace('contract_sentinal', '');
  }
  param = param || '';
  size = '?size=' + size;
  page = '&page=' + page;
  let url = CORS_WORKAROUND + CONFIG.URL.DOCUMENTS.GET + size + page + param;
  url = url.replace('$id', id);
  return new Promise((resolve, reject) => {
    get(url).then((resp) => {
      let data = resp.data;
      let headers = resp.headers;
      resolve({ data, headers });

    }, (e) => {
      reject(e);
    });
  });
}

function getEntities(cid, did) {
  let url = CORS_WORKAROUND + CONFIG.URL.ENTITIES.GET;
  url = url.replace('$cid', cid).replace('$did', did);
  return new Promise((res, rej) => {
    get(url).then((resp) => {
      let data = resp.data;
      res(data);

    }, (e) => {
      rej(e);
    });
  });

}

function getEntitiesToken(cid, did) {
  let url = CORS_WORKAROUND + CONFIG.URL.ENTITIES_TOKEN.GET;
  url = url.replace('$cid', cid).replace('$did', did);
  return new Promise((res, rej) => {
    get(url).then((resp) => {
      res(resp.data);

    }, (e) => {
      rej(e);
    });
  });

}

function fetchtBoundingBoxData(cid, did, imagePath, width, height, x1, y1, x2, y2) {
  console.log(arguments);
  let url = CORS_WORKAROUND + CONFIG.URL.BOUNDING_BOX_CREATE_DATA.GET;
  url = url.replace('$cid', cid)
    .replace('$did', did)
    .replace('$image', imagePath)
    .replace('$width', width)
    .replace('$height', height)
    .replace('$x1', Math.round(x1))
    .replace('$y1', Math.round(y1))
    .replace('$x2', Math.round(x2))
    .replace('$y2', Math.round(y2));

  return new Promise((res, rej) => {
    get(url).then((resp) => {
      res(resp.data);

    }, (e) => {
      rej(e);
    });
  });
}

function getBoundingBox(cid, did, imagePath, width, height) {
  let url = CORS_WORKAROUND + CONFIG.URL.BOUNDING_BOX.GET;
  url = url.replace('$cid', cid)
    .replace('$did', did)
    .replace('$image', imagePath)
    .replace('$width', width)
    .replace('$height', height);

  return new Promise((res, rej) => {
    get(url).then((resp) => {
      res(resp.data);

    }, (e) => {
      rej(e);
    });
  });

}

function getDocument(cid, did) {
  let url = CORS_WORKAROUND + CONFIG.URL.DOCUMENT.GET;
  url = url.replace('$cid', cid).replace('$did', did);

  return new Promise((res, rej) => {
    get(url).then((resp) => {
      let data = resp.data;
      res(data);

    }, (e) => {
      rej(e);
    });
  });
}

const getSimilarDocuments = (cid, did) => {
  const url = (CORS_WORKAROUND + CONFIG.URL.DOCUMENT.SIMILAR_DOCUMENTS).replace('$cid', cid).replace('$did', did);

  return new Promise((res, rej) => {
    get(url).then((resp) => {
      res(resp?.data);
    }, (e) => {
      rej(e);
    })
  })
}

const getDocumentStructure = (cid, did) => {
  const url = (CORS_WORKAROUND + CONFIG.URL.DOCUMENT.STRUCTURE).replace('$cid', cid).replace('$did', did);

  return new Promise((res, rej) => {
    get(url).then((resp) => {
      res(resp?.data);
    }, (e) => {
      rej(e);
    })
  })
}

const getSimilarClauses = (cid, did, index) => {
  const url = (CORS_WORKAROUND + CONFIG.URL.DOCUMENT.SIMILAR_CLAUSES).replace('$cid', cid).replace('$did', did).replace('$index', index);

  return new Promise((res, rej) => {
    get(url).then((resp) => {
      res(resp?.data);
    }, (e) => {
      rej(e);
    })
  })
}

function downloadDocument(cid, did) {
  let url = CORS_WORKAROUND + CONFIG.URL.DOCUMENT.DOWNLOAD;
  url = url.replace('$cid', cid).replace('$did', did);

  return new Promise((res, rej) => {
    get(url, { responseType: 'blob' }).then((resp) => {
      res(resp);
    }, (e) => {
      rej(e);
    });
  });
}

function getSearchableDocument(cid, did) {
  let url = CORS_WORKAROUND + CONFIG.URL.DOCUMENT.DOWNLOAD + '?type=search';
  url = url.replace('$cid', cid).replace('$did', did);

  return new Promise((res, rej) => {
    get(url, { responseType: 'blob' }).then((resp) => {
      res(resp);

    }, (e) => {
      rej(e);
    });
  });
}

function getMappings(cid, did) {
  let url = CORS_WORKAROUND + CONFIG.URL.MAPPINGS.GET;
  url = url.replace('$cid', cid).replace('$did', did);
  return new Promise((res, rej) => {
    get(url).then((resp) => {
      let data = resp.data;
      res(data);

    }, (e) => {
      rej(e);
    });
  });
}

function getGroups() {
  let url = CORS_WORKAROUND + CONFIG.URL.GROUPS.GET;
  return new Promise((res, rej) => {
    get(url).then((resp) => {
      let data = resp.data;
      res(data);

    }, (e) => {
      rej(e);
    });
  });
}

function uploadDoc(cid, groupName, fileData) {
  let url = CORS_WORKAROUND + CONFIG.URL.DOCUMENT.POST
  if (groupName) {
    url += '?group=' + groupName;
  }
  url = url.replace('$cid', cid);

  return new Promise((res, rej) => {
    post(url, fileData).then((resp) => {
      let data = resp.data;
      res(data);

    }, (e) => {
      rej(e);
    });
  });
}

function updateEntities(cid, did, param) {
  let url = CORS_WORKAROUND + CONFIG.URL.ENTITIES.PUT;
  url = url.replace('$cid', cid).replace('$did', did);
  return new Promise((res, rej) => {
    put(url, param).then((resp) => {
      let data = resp.data;
      res(data);

    }, (e) => {
      rej(e);
    });
  });
}

function updateMappings(cid, did, type, param, headerParam) {
  let url = CORS_WORKAROUND + CONFIG.URL.MAPPINGS.POST;
  url = url.replace('$cid', cid).replace('$did', did);
  url += `?mode=${type || 'save'}`

  return new Promise((res, rej) => {
    post(url, param, headerParam, { validateStatus: (status) => status >= 200 }).then(({ data, status }) => {
      if (status === 200) {
        res(data.message)
      } else {
        rej(data.message)
      }
    }, (e) => {
      rej(e);
    });
  });
}

function deleteDocument(cid, did) {
  let url = CORS_WORKAROUND + CONFIG.URL.DOCUMENT.DELETE;
  url = url.replace('$cid', cid).replace('$did', did);
  return new Promise((res, rej) => {
    deleteApi(url).then((resp) => {
      let data = resp.data;
      res(data);

    }, (e) => {
      rej(e);
    });
  });
}

function deleteAllDocuments(cid) {
  if (cid) {
    let url = CORS_WORKAROUND + CONFIG.URL.COLLECTIONS.DELETE;
    url = url.replace('$cid', cid) + "?mode=clean"

    return new Promise((res, rej) => {
      deleteApi(url).then((resp) => {
        let data = resp.data;
        res(data);

      }, (e) => {
        rej(e);
      });
    });
  }
}

/**
 * responsible for exporting File (Import Export)
 */

function exportFile(data) {
  let url = CORS_WORKAROUND + CONFIG.URL.IMPORTEXPORT.POST;
  return new Promise((resolve, reject) => {
    post(url, data).then((response) => {
      resolve(response.data);
    }, (e) => {
      reject(e);
    });
  });
}

function logOut() {
  store.dispatch({ type: "USER_LOGOUT" })
  const origin = getDecryptedItem('origin') || "cloudhub360"
  sessionStorage.clear();
  localStorage.clear();
  setEncryptedItem("origin", origin)

  if (origin) {
    window.location.href = `/login?origin=${origin}`
  } else {
    window.location.href = "/login"
  }
}

const forceLogOut = () => {
  logOut()
  setTimeout(() => {
    window.location.reload(true)
  }, 500)
}

const getCloudhubUserInfoFromAuth0Token = (token) => {
  const url = `${CLOUDHUB_BASE_URL}/api/token/userInfo`;
  return new Promise((res, rej) => {
    get(url, { token }).then(
      (resp) => {
        const { data } = resp;
        res(data);
      },
      (e) => {
        rej(e);
      }
    );
  });
};

const getContractSentinelUserInfoFromAuth0Token = (token) => {

  return new Promise((resolve, reject) => {
    getContractSentinelUserUuidFromToken(token)
      .then((response) => {
        const { status, data, errorMessage, jsonData } = response

        if (status) {
          const url = `${CLOUDHUB_BASE_URL}/api/token/UUID`;

          get(url, { token: data || jsonData }).then(
            (resp) => {
              const { data: userInfo } = resp;
              resolve(userInfo);
            },
            (e) => {
              reject(e);
            }
          );
        } else {
          reject(errorMessage)
        }
      })
      .catch((err) => {
        reject(err);
      })

  });
};

const getContractSentinelUserUuidFromToken = (token) => {
  const url = `${CONTRACT_SENTINEL_BASE_URL}/api/token/UUID`;

  return new Promise((res, rej) => {
    get(url, { token }).then(
      (resp) => {
        const { data } = resp;
        res(data);
      },
      (err) => {
        rej(err.response?.data?.message || err);
      }
    );
  });
};

export {
  get,
  post,
  put,
  deleteApi,
  getCollections,
  getContractManagement,
  getCollection,
  getFilters,
  getDocuments,
  getDocument,
  getSimilarDocuments,
  getDocumentStructure,
  getSimilarClauses,
  downloadDocument,
  getSearchableDocument,
  getEntities,
  getMappings,
  uploadDoc,
  updateEntities,
  updateMappings,
  deleteDocument,
  deleteAllDocuments,
  getGroups,
  getEntitiesToken,
  getLockStatus,
  acquireLock,
  releaseLock,
  userLoginCloudhub,
  exportFile,
  getBoundingBox,
  getCustomCall,
  fetchtBoundingBoxData,
  logOut,
  forceLogOut,
  getCloudhubUserInfoFromAuth0Token,
  getContractSentinelUserInfoFromAuth0Token,
  userLoginContractSentinel
}
