import React from "react"
import classNames from "classnames"

import cloudhubLogo from "../../../assets/icons/cloudhub-logo.png"
import contractSentinelLogo from "../../../assets/icons/contract-sentinel-logo.png"

import "../../../assets/scss/components/app-loader.scss"
import { getDecryptedItem } from "../../../utility/encryptedStorage"

class SpinnerComponent extends React.Component {
  state = {
    origin
  }

  componentDidMount() {
    this.setState({ origin: getDecryptedItem('origin') })
  }

  render() {
    const { origin } = this.state

    return (
      <div className="fallback-spinner vh-100">
        <img
          className={classNames("fallback-logo", {
            "blinking-loader": origin === 'contract-sentinel'
          })}
          src={origin === 'contract-sentinel' ? contractSentinelLogo : cloudhubLogo}
          alt="logo"
        />
        <div className="loading">
          <div className="effect-1 effects"></div>
          <div className="effect-2 effects"></div>
          <div className="effect-3 effects"></div>
        </div>
      </div>
    )
  }
}

export default SpinnerComponent
