import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHome, faList,
  // faCog
} from '@fortawesome/free-solid-svg-icons'
const navigationConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <FontAwesomeIcon className="color-green" icon={faHome} />,
    permissions: ["admin", "editor"],
    navLink: "/"
  },

  {
    id: "documents",
    title: "Work Queues",
    type: "item",
    icon: <FontAwesomeIcon className="color-green" icon={faList} />,
    navLink: "/documents",
    permissions: ["admin", "editor"]
  }
  // {
  //   id: "configurations",
  //   title: "Configurations",
  //   type: "item",
  //   icon: <FontAwesomeIcon className="color-green" icon={faCog} />,
  //   // navLink: "/",
  //   permissions: ["admin", "editor"]
  // }
]

export default navigationConfig
