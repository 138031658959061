/**
 * responsible fetching the settings from url
 */
export const settingFromUrl = (value = "settings") => {
  try {
    var url = new URL(window.location.href);
    var status = url.searchParams.get(value);
    if (status)
      return Boolean(status);
    else
      return false;
  } catch (e) {
    return false;
  }
}

export const updateContractSentinal = (collectionData) => {
  const isContractSeninalActive = settingFromUrl("contract_sentinal");
  if (isContractSeninalActive) {
    //adding contract sentinal
    let [sampleData] = collectionData;
    const data = { ...sampleData };
    data.name = "Contract Sentinal";
    data.id += "contract_sentinal";
    //updating in the collection array
    collectionData.push(data);
  }

  return collectionData;
}

export const isContractSeninalId = (id) => {
  return id.match(RegExp("contract_sentinal", "g")) ? true : false
}
