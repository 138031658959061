// const initialState = {
//   modalAppNotAllowed: {
//     emableModel: false,
//     enableOkButton: true,
//   },
//   modalLoginFailed: {
//     emableModel: false,
//     enableOkButton: true,
//   },
//   modalResourceLock: {
//     emableModel: false,
//     message: "",
//     displayMsg: "",
//     enableCancleButton: true,
//     enableOkButton: true,
//     headerMsg: ""
//   }
// }

export const login = (state = { userRole: "admin" }, action) => {
  switch (action.type) {
    case "LOGIN_WITH_EMAIL": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_FB": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_TWITTER": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_GOOGLE": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_GITHUB": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_JWT": {
      return { ...state, values: action.payload }
    }
    case "LOGOUT_WITH_JWT": {
      return { ...state, values: action.payload }
    }
    case "LOGOUT_WITH_FIREBASE": {
      return { ...state, values: action.payload }
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.userRole }
    }
    case "OPEN_MODEL_APP_NOT_ALLOWED": {
      return {
        ...state,
        modalAppNotAllowed: action.value,
      }
    }
    case "OPEN_MODEL_LOGIN_FAILED": {
      return {
        ...state,
        modalLoginFailed: action.value,
      }
    }
    case "OPEN_MODEL_RESOURCE_LOCK": {
      return {
        ...state,
        modalResourceLock: action.value,
      }
    }
    default: {
      return state
    }
  }
}
