import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"

const appReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar
})

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export default rootReducer
