import React, { useEffect, useMemo, useState } from "react"
import ScrollToTop from "react-scroll-up"
import { Button } from "reactstrap"
import { ArrowUp } from "react-feather"
import classnames from "classnames"
import { getDecryptedItem } from "../../../utility/encryptedStorage"

const DEFAULT_CONFIG = {
  year: new Date().getFullYear(),
  link: "https://demo.cloudhub360.com/",
  organsiation: "Cloudhub360 Ltd."
}

const Footer = props => {
  let footerTypeArr = ["sticky", "static", "hidden"]
  const [config, setConfig] = useState(DEFAULT_CONFIG)
  const [origin, setOrigin] = useState("")

  useEffect(() => {
    const originFromState = getDecryptedItem('origin')
    setOrigin(originFromState)
    if (originFromState === 'contract-sentinel') {
      setConfig({
        ...DEFAULT_CONFIG,
        link: "https://www.contractsentinel.com/",
        organsiation: "Contract Sentinel"
      })
    } else {
      setConfig(DEFAULT_CONFIG)
    }
  }, [])

  const { year, link, organsiation } = useMemo(() => config, [config])

  return (
    <footer
      className={classnames("footer footer-light", {
        "footer-static": props.footerType === "static" || !footerTypeArr.includes(props.footerType),
        "d-none": props.footerType === "hidden",
        "ml-0": origin === 'contract-sentinel'
      })}
    >
      <p className="mb-0 clearfix">
        <span className="float-md-left d-block d-md-inline-block mt-25">
          <span>
            COPYRIGHT &copy; {year}
            <a className="ml-25" href={link} target="_blank" rel="noopener noreferrer">
              {organsiation}
            </a>
            <span className="d-none d-sm-inline-block">, All rights Reserved</span>
          </span>
        </span>
      </p>
      {props.hideScrollToTop === false ? (
        <ScrollToTop showUnder={160}>
          <Button color="primary" className="btn-icon scroll-top">
            <ArrowUp size={15} />
          </Button>
        </ScrollToTop>
      ) : null}
    </footer>
  )
}

export default Footer
