import React from 'react';
import ReactDOM from 'react-dom'

import queryString from "query-string"

import { Alert } from 'reactstrap'
import { Promise } from 'ag-grid-community';

import ComponentSpinner from './../components/@vuexy/spinner/Loading-spinner'
import {
  getCloudhubUserInfoFromAuth0Token, getContractSentinelUserInfoFromAuth0Token,
  acquireLock, releaseLock, get, logOut
} from './context/API'
import { history } from "./../history"

import { getDecryptedItem, setEncryptedItem, removeEncryptedItem } from './encryptedStorage';

function getValueFromObj(array, val) {
  let res = array.find(item => (
    item.label === val
  ));

  if (res) {
    return res.val;
  } else {
    return 0
  }
}

function setEnviournmentNews() {
  const URL = {
    BASE: 'https://newsapi.org/v2/top-headlines',
    API_KEY: '0dc8ac7f0e564f7a98de3c56a0d9c640',
    COUNTRY: 'in',
    getDefaultUrl: function (country = this.COUNTRY) {
      return this.BASE + '?apikey=' + this.API_KEY + '&country=' + country;
    }
  };

  get(URL.getDefaultUrl()).then((promise) => {
    // setNews(promise.data.articles);
    console.log(promise.data.articles);
  }, function (e) {
    console.error(e);
  });
}


function limitString(string, limit) {
  return string.substring(string, limit);
}

function checkAndSetData(key, getData, setCollectionData) {
  let data = getData(key);
  if (data && data.length > 0) {
    setCollectionData(data);
    return true;
  }
  return false;
}

function alert(message, type) {
  ReactDOM.render(<Alert color={type || 'primary'}>
    {message}
  </Alert>, document.getElementById('custom-alert'));

  setTimeout(() => {
    ReactDOM.render(<></>, document.getElementById('custom-alert'));
  }, 3000);
}

/**
  * resonsible for showing the loader
 */
function showLoader(statusBit) {
  ReactDOM.render(<ComponentSpinner show={statusBit} />,
    document.getElementById('custom-loader'));
}

const fetchAndSetUserData = ({ origin, token }) => {
  switch (origin) {
    case "contract-sentinel":

      return getContractSentinelUserInfoFromAuth0Token(token).then((response) => {
        const {
          loginInfo: auth0LoginInfo,
          status: auth0status,
          errorMessage: auth0ErrorMessage
        } = response
        if (auth0status && auth0LoginInfo) {
          const userInfo = auth0LoginInfo?.userInfo;

          const userData = setUserData(userInfo)
          return userData;
        } else {
          if (auth0ErrorMessage) {
            console.error(auth0ErrorMessage)
          }
          logOut();
          return null;
        }
      }, (err) => {
        console.error(err);
        logOut();
        return null;
      })
    case "cloudhub360":
    default:
      return getCloudhubUserInfoFromAuth0Token(token).then(async ({
        loginInfo: auth0LoginInfo,
        status: auth0status,
        errorMessage: auth0ErrorMessage
      }) => {
        if (auth0status && auth0LoginInfo) {
          const userInfo = auth0LoginInfo?.userInfo;

          const userData = setUserData(userInfo)
          return userData;
        } else {
          if (auth0ErrorMessage) {
            console.error(auth0ErrorMessage)
          }
          logOut();
          return null;
        }
      }, (err) => {
        console.error(err);
        logOut();
        return null;
      });
  }
}

/**
 * responsible for getting the user details
 */
const handleUserDetails = () => {
  const { origin: originNew, token: tokenNew } = queryString.parse(window.location.search)

  const originOld = getDecryptedItem("origin")
  const tokenOld = getDecryptedItem("token")

  const origin = originNew || originOld;
  const token = tokenNew || tokenOld;
  if (originNew) {

    // remove this code after 2-3 months viz. May 2023
    sessionStorage.removeItem("origin")
    removeEncryptedItem("origin")
    // remove this code after 2-3 months viz. May 2023

    setEncryptedItem("origin", origin || "cloudhub360")
  }

  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  const BASE_URL = document?.location?.origin || ""

  switch (origin) {
    case "contract-sentinel":
      document.title = "Contract Sentinel | Workbench";
      link.href = `${BASE_URL}/favicon-cs.png`;
      break;
    case "cloudhub360":
    default:
      document.title = "Cloudhub360 | Workbench";
      link.href = `${BASE_URL}/favicon.ico`;
      break;
  }

  return new Promise(async (res, rej) => {
    try {
      const isLoggedIn = getDecryptedItem("loggedIn") === "true";
      const isUserDataAvailable = getDecryptedItem("user-data");
      let userData = null
      if (isUserDataAvailable) {
        userData = JSON.parse(isUserDataAvailable);
      }

      if (isLoggedIn) {
        if (!originNew && isUserDataAvailable) {
          res(userData);
        } else {
          // url data mismatch
          if (originNew && !tokenNew) {
            logOut();
            res(null);
          } else if (originOld && !tokenOld) {
            logOut();
            res(null);
          } else {
            setEncryptedItem("token", tokenNew)
            const userData = await fetchAndSetUserData({ origin, token });
            res(userData)
          }
        }
      } else {
        if (origin && token) {
          setEncryptedItem("token", tokenNew)
          const userData = await fetchAndSetUserData({ origin, token });
          res(userData)
        } else {
          logOut();
          res(null);
        }
      }
    } catch (e) {
      console.warn(e);
    }
  });
}

const setUserData = (userData) => {
  if (userData && userData.id) {
    setEncryptedItem("user-data", userData);
    setEncryptedItem("loggedIn", true);

    history.push("/");
    return userData;
  } else {
    logOut();
    return null;
  }
}

//responsible for acquiring a lock in the document
const lockDocument = (documentId) => {
  return new Promise((resolve, reject) => {
    acquireLock(documentId).then((resp) => {
      //saving the data in the session storage

      if (resp.status === "success") {
        console.log("lock acquired: ", documentId);
        setEncryptedItem("locked_doc_id", documentId);
        reject(false);
      } else {
        console.log("lock failed to acquired: ", documentId);
        resolve(true);
      }
    }, (err) => {
      console.error(`unable to acquire the lock`);
      reject(false);
    });
  });
}

//responsible for releasing a lock in the document
const releaseDocumentLock = () => {
  return new Promise((resolve, reject) => {
    //check if there is some data existing in the session storage
    let existingLockedDoc = getDecryptedItem("locked_doc_id");
    if (existingLockedDoc !== '') {
      //release lock
      releaseLock(existingLockedDoc).then((resp) => {
        if (resp.status === "success") {
          console.log("lcok released: ", existingLockedDoc);
          setEncryptedItem("locked_doc_id", '');
          resolve(true);
        } else {
          console.log("error occurred while releasig lock - " + resp.message);
          reject(false);
        }
      });
    } else {
      console.warn("Nothing to release");
      reject(false);
    }
  })
}

export {
  getValueFromObj,
  setEnviournmentNews,
  limitString,
  checkAndSetData,
  alert,
  showLoader,
  handleUserDetails,
  lockDocument,
  releaseDocumentLock,
  fetchAndSetUserData
}
